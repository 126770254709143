<template>
  <div class="px-3">

   
    <!-- Accordion Start -->
    <div class="card-body pb-2 px-0">
      <div id="rangeSettingsAccordion">
        <div class="headingText">
          <strong class="">
            <span>Slider Settings</span>

            <a
              block
              v-b-toggle.rangeSettingsAcc
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#rangeSettingsAccordionTarget"
              aria-expanded="false"
              aria-controls="rangeSettingsAccordionTarget"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse
          id="rangeSettingsAcc"
          accordion="my-accordion"
          role="tabpanel"
        >
          <div class="row my-2 text-setting">

            <!-- Thumb Styles Start -->
            <div class="col-12">
              <h4 class="editorStyleTitle mt-2">Select Slider:</h4>
              <div class="form-group d-flex align-items-center">
                <select
                class="form-select w-100 form-control-sm pl-2 ml-2 input-border-style"
                aria-label="Default select example"
                v-model="block.currInputMode"
              >
                <option value="exact">Exact</option>
                <option value="range">Range</option>
              </select>
              </div>

              
            </div>
            <!-- Thumb Styles End -->
            <!-- Thumb Styles Start -->
            <div class="col-12">
              <h4 class="editorStyleTitle mt-2">Select Purpose:</h4>
              <div class="form-group d-flex align-items-center">
                <select
                class="form-select w-100 form-control-sm pl-2 ml-2 input-border-style"
                aria-label="Default select example"
                v-model="block.selectedPurpose"
              >
                <option v-for="(purpose,index) in block.purposeArr  " :key="index" :value="purpose.type">{{ purpose.title }}</option>
              </select>
              </div>

              <div class="mt-2" v-if="block.selectedPurpose == 'other'">
                <h4 class="editorStyleTitle mt-2">Other Purpose:</h4>
                <div class="d-flex align-items-center">
               
                <input
                  type="text"
                  v-model="block.otherPurposeKey"
                  placeholder="Mention your purpose"
                  class="form-control-sm w-100 px-2 ml-1 input-border-style"
                />
              </div>
              </div>
            </div>
            <!-- Thumb Styles End -->


            <div class="col-12">
              <h4 class="editorStyleTitle mt-2">Min - Max Values:</h4>
              <div class="form-group d-flex justify-content-between align-items-center">

                  <div >
                    <input
                  type="number"
                  v-model="block.minValue"
                  placeholder="Min Value"
                  class="form-control-sm w-50 px-2 ml-1 input-border-style"
                />
                  </div>
                  <div >
                    <input
                  type="number"
                  v-model="block.maxValue"
                  placeholder="Max Value"
                  class="form-control-sm w-50 px-2 ml-1 input-border-style"
                />
                  </div>

              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- Accordion End -->
    <!-- Accordion Start -->
    <div class="card-body pb-2 px-0">
      <div id="rangeStylesAccordion">
        <div class="headingText">
          <strong class="">
            <span>Slider Styles</span>

            <a
              block
              v-b-toggle.rangeStylesAcc
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#rangeStylesSettings"
              aria-expanded="false"
              aria-controls="rangeStylesSettings"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse
          id="rangeStylesAcc"
          accordion="my-accordion"
          role="tabpanel"
        >
          <div class="row my-2 text-setting">
            <div class="col-8 d-flex align-items-center pr-1 mb-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1"
              >
                <path
                  d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                  fill="#B3AFB6"
                />
              </svg>

              <select
                class="form-select w-100 form-control-sm pl-2 ml-2 input-border-style"
                aria-label="Default select example"
                v-model="block.style.fontFamily"
              >
              <option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>
              </select>
            </div>
            <div class="col-4 px-0">
              <div class="d-flex align-items-center">
                <span class="mr-1">
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                      fill="#B3AFB6"
                    />
                    <path
                      d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  style="width: 50px"
                  v-model="block.style.fontSize"
                  class="form-control-sm w-75 px-2 ml-1 input-border-style"
                />
              </div>
            </div>

            <!-- Thumb Styles Start -->
            <div class="col-12">
              <h4 class="editorStyleTitle mt-2">Thumb Size:</h4>
              <div class="form-group d-flex align-items-center">
                <input
                  type="range"
                  class="form-control-range formControlRange"
                  min="6"
                  max="33"
                  step="1"
                  :key="block.style.thumbSize"
                  v-model="block.style.thumbSize"
                />

                <span class="ml-3">{{ block.style.thumbSize }}px</span>
              </div>
            </div>
            <!-- Thumb Styles End -->
            <div class="col-12">
              <h4 class="editorStyleTitle mt-2">Track Height:</h4>
              <div class="form-group d-flex align-items-center">
                <input
                  type="range"
                  class="form-control-range formControlRange"
                  min="6"
                  max="22"
                  step="1"
                  :key="block.style.trackHeight"
                  v-model="block.style.trackHeight"
                />

                <span class="ml-3">{{ block.style.trackHeight }}px</span>
              </div>
            </div>
            <div class="col-12 ">
              <h4 class="editorStyleTitle mt-2">Primary Color:</h4>
              <div class="form-group d-flex align-items-center">
                <input
                  type="color"
                  v-model="block.style.progressFill"
                  style="
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    padding: 0px;
                  "
                  class="form-control-color form-control input-border-style"
                />
                <input
                  type="text"
                  class="ColorTextInput input-border-style"
                  v-model="block.style.progressFill"
                />
              </div>
                
              </div>
            <div class="col-12 ">
              <h4 class="editorStyleTitle mt-2">Secondary Color:</h4>
              <div class="form-group d-flex align-items-center">
                <input
                  type="color"
                  v-model="block.style.progressBg"
                  style="
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    padding: 0px;
                  "
                  class="form-control-color form-control input-border-style"
                />
                <input
                  type="text"
                  class="ColorTextInput input-border-style"
                  v-model="block.style.progressBg"
                />
              </div>
                
              </div>
            <div class="col-12 " v-if="false">
              <h4 class="editorStyleTitle mt-2">Min-Max Font Size:</h4>
              <div class="form-group d-flex align-items-center">
                <input
                  type="number"
                  v-model="block.style.minMaxFontSize"
                  style="
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                    padding: 0px;
                  "
                  class="form-control-color form-control input-border-style"
                />
              </div>
                
              </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- Accordion End -->

    <div class="">
      <div class="row m-0" v-if="isDesktopView">
        <h4 class="editorStyleTitle my-4">Margin:</h4>
        <div class="row align-items-center mb-2">
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginTop"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginBottom"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2 mt-3 align-items-center">
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginLeft"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-6 d-flex mb-0">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.marginRight"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile Margin:</h4>
        <div class="row m-0">
          <div class="row align-items-center">
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Top"
                    v-model="block.style.mobileMarginTop"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Bottom"
                    v-model="block.style.mobileMarginBottom"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginLeft"
                    min="0"
                    placeholder="Left"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginRight"
                    min="0"
                    placeholder="Right"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  components: {},
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
  },
  data() {
    return {
      showBgOpacity: true,
    };

    //   this.contentLocal = this.content;
  },
  computed: {
    getFontFamilyList() {
return fontFamilyList;
},
  },
  methods: {
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorOutput(value) {
      let isHex = this.checkHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
  },
};
</script>
<style>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.reset-option {
  border: 0.3px solid #6d6b6d;
  box-sizing: border-box;
  border-radius: 4px;
}
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}
.active-svg path {
  fill: #323232;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.button-group {
  color: #535353;
  outline: none;
}
.btn-group {
  border: 1px solid #e0dee2;
  border-radius: 2px;
}
.btn-group .btn {
  border: none;
}
.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
</style>
